<template>
    <div id="main_screen">
        <nav class="navbar navbar-expand-xl navbar-dark" style="background-color: #1227aa;">
            <div class="navbar-left">
                <img src="./../assets/gd_purple_white.png" style="height: 50px;cursor: pointer" @click="showNav = false; $router.push('/')">
            </div>
            <button class="navbar-toggler" type="button" @click="showNav =! showNav">
                <span class="navbar-toggler-icon"/>
            </button>
            <div class="collapse navbar-collapse" :class="{show: showNav}">
                <b-navbar-nav>
                    <li @click="showNav = false" class="nav-item">
                        <router-link class="nav-link" to="/shipments">Отправления</router-link>
                    </li>
                    <li @click="showNav = false" class="nav-item" >
                        <router-link class="nav-link" to="/places">Места</router-link>
                    </li>
                    <li @click="showNav = false" class="nav-item">
                        <router-link class="nav-link" to="/registers">Сборочные реестры</router-link>
                    </li>
                    <li @click="showNav = false" class="nav-item">
                        <router-link class="nav-link" to="/delivery-registers">Доставочные реестры</router-link>
                    </li>
                    <li @click="showNav = false" class="nav-item">
                        <router-link class="nav-link" to="/sorting-centers">СЦ</router-link>
                    </li>
                    <li @click="showNav = false" class="nav-item">
                        <router-link class="nav-link" to="/test-generator">Генератор тестовых данных</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/login" @click="exit">Выйти</router-link>
                    </li>
                </b-navbar-nav>
            </div>
        </nav>
        <router-view class="router"/>
    </div>
</template>

<script>
    export default {
        name: 'MainScreen',
        created() {
            if (!localStorage.getItem("jwt")) {
                this.$router.push('/login');
            }
            this.admin = this.$root.isAdmin();
            // this.$root.getAuthorities();
        },
        data() {
            return {
                showNav: false,
                admin: false
            }
        },
        methods: {
            exit() {
                localStorage.removeItem("jwt");
            }
        }
    }
</script>

<style scoped>
    #main_screen {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .router {
        padding: 10px 10px 70px !important;
        height: 100%;
        width: 100%;
        overflow: auto;
    }
    @-moz-document url-prefix() {
        .router:after {
            content: "";
            height: 150px;
            display: block;
        }
    }
    @supports (-ms-ime-align: auto) {
        .router:after {
            content: "";
            height: 150px;
            display: block;
        }
    }
    @media all and (-ms-high-contrast: none) {
        .router:after {
            content: "";
            height: 150px;
            display: block;
        }
    }
</style>
